export enum DefaultScreen {
  FORGOT = "forgot",
  VERIFY = "verify",
  RESET = "reset",
}
export const Error = {
  EMAIL_NOT_REGISTERED:
    "Email id is not register with us. Please contact administrator.",
  VERIFY_ERROR: "Invalid verification code. Please match/generate again.",
  GENERIC_ERROR: "Something went wrong. Please contact administrator",
  EXISTS_EMAIL:
    "Sorry. That email has already been registered. Please choose another.",
} as const;

export const Message = {
  ENTER_PASSWORD: "Please enter new password.",
  UPDATE_SUCCESS: "Updated successfully.",
  PAYOUT_SUCCESS: "Payout successfully.",
  NO_REVIEW: "No Review found.",
} as const;
