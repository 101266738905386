<app-loader></app-loader>
<section>
  <mat-card class="login-card">
    <mat-card-header>
      <mat-card-title>
        <h3 class="login-text">Login Panel</h3>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form class="login-form" [formGroup]="loginForm" (submit)="login()">
        <table class="login-full-width" cellspacing="0">
          <tr>
            <td>
              <mat-form-field class="login-full-width">
                <input matInput placeholder="Email" type="text" formControlName="email" autocomplete="disabled" />
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field class="login-full-width">
                <input matInput placeholder="Password" type="password" formControlName="password"
                  autocomplete="disabled" />
              </mat-form-field>
            </td>
          </tr>
        </table>
        <mat-error *ngIf="loginError">
          {{errorMsg}}
        </mat-error>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button (click)="login()" color="primary" [disabled]="!loginForm.valid">Login</button>
      <a mat-stroked-button routerLink="/forgot">Not able to login ?</a>
    </mat-card-actions>
  </mat-card>
</section>
<app-footer></app-footer>