import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IAdmin } from "../../Interfaces/admin.interface";
import { LoginRequest } from "../../interfaces/login-request.interface";
import { LoginResponse } from "../../interfaces/login-response.interface";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  
  baseUrl = environment.baseUrl;

  private currentUserSubject: BehaviorSubject<IAdmin>;

  public currentUser: Observable<IAdmin>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<IAdmin>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }
  
  /**
   * Getter to get current user data
   */
  public get currentUserValue(): IAdmin {
    return this.currentUserSubject.value;
  }
  
  /**
   * Method to set token in local storage and redirect to 
   * @param {LoginRequest} authData
   */
  auth(authData: LoginRequest, url: string) {
    return this.http.post<LoginResponse>(`${this.baseUrl}${environment.adminEndPoint}auth/${url}`, authData)
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(user));
            this.currentUserSubject.next(user);
          }
          return user;
        })
      );
  }
  
  /**
   * method to remove user token from local storage to log user out
   */
  logout() {
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
  }

}
