import { Routes } from "@angular/router";
import { LoginComponent } from "../components/core/login/login.component";
import { AdminComponent } from "../components/core/admin/admin.component";
import { AuthGuard } from "../guards/auth.guard";
import { ForgotComponent } from "../components/core/forgot/forgot.component";
import { HomeComponent } from "../components/core/home/home.component";
import { TermsConditionsComponent } from "../components/core/terms-conditions/terms-conditions.component";
import { PrivacyPolicyComponent } from "../components/core/privacy-policy/privacy-policy.component";
import { RefundPolicyComponent } from "../components/core/refund-policy/refund-policy.component";

export const routes: Routes = [
  //{ path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "", component: HomeComponent},
  { path: "login", component: LoginComponent },
  { path: "forgot", component: ForgotComponent },
  { path: "forgot/:id", component: ForgotComponent },
   {path:"terms",component:TermsConditionsComponent},
   {path:"policy",component:PrivacyPolicyComponent},
   {path:"refundPolicy",component:RefundPolicyComponent},


  {
    path: "admin",
    component: AdminComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: "Dashboard",
    },
    children: [
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("../components/feature/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "user",
        loadChildren: () =>
          import("../components/feature/customer/customer.module").then(
            (m) => m.CustomerModule
          ),
        data: {
          breadcrumb: "Users",
        },
      },
      {
        path: "advisors",
        loadChildren: () =>
          import("../components/feature/vendor/vendor.module").then(
            (m) => m.VendorModule
          ),
        data: {
          breadcrumb: "Advisors",
        },
      },
      {
        path: "content",
        loadChildren: () =>
          import(
            "../components/feature/content-management/content-management.module"
          ).then((m) => m.ContentManagementModule),
        data: {
          breadcrumb: "Content",
        },
      },
      {
        path: "settings",
        loadChildren: () =>
          import("../components/feature/settings/settings.module").then(
            (m) => m.SettingsModule
          ),
        data: {
          breadcrumb: "settings",
        },
      },
      {
        path: "payment",
        loadChildren: () =>
          import("../components/feature/payment/payment.module").then(
            (m) => m.PaymentModule
          ),
        data: {
          breadcrumb: "Payment",
        },
      },
      {
        path: "help",
        loadChildren: () =>
          import("../components/feature/help/help.module").then(
            (m) => m.HelpModule
          ),
        data: {
          breadcrumb: "Help",
        },
      },
    ],
  },
];
