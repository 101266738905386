import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "src/app/services/core/authentication.service";
import { FormGroup, FormBuilder } from "@angular/forms";
import { LoginValidationConfig } from "src/app/validators/login-validation-config";
import { first, take } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ToasterService } from "src/app/services/toaster.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  returnUrl: string;
  loginError = false;
  errorMsg: string;

  constructor(
    private router: Router,
    private loginService: AuthenticationService,
    private fb: FormBuilder,
    private vc: LoginValidationConfig,
    private activatedRoute: ActivatedRoute,
    private readonly toasterService: ToasterService
  ) {}

  /**
   * initializes the component
   */
  ngOnInit() {
    // get return url from route parameters or default to '/'
    //this.returnUrl = this.router.snapshot.queryParams["returnUrl"] || "/admin";
    this.returnUrl =
      this.activatedRoute.snapshot.queryParams["returnUrl"] || "/admin";
    this.loginForm = this.fb.group(this.vc.getLoginForm());
  }

  /**
   * processes the login credentials
   * @memberof LoginComponent
   */
  login() {
    this.loginService
      .auth(this.loginForm.value, "check")
      .pipe(first())
      .subscribe(this.loginObserver);
  }

  private loginObserver = {
    next: () => {
      this.router.navigate([this.returnUrl]);
    },
    error: (err) => {
      this.loginError = true;
      // this.errorMsg = `Login Failed: ${err.error.messages.error}`;
      this.toasterService.openSnackBar(
        "Login Failed: Invalid email or password.",
        null,
        true
      );
    },
    complete: () => {},
  };
}
