<div class="middle" style="text-align:center;">
    <h1>Get In Touch</h1>
    <mat-list>
      <mat-list-item> <div class="example-button-container">
        <button mat-mini-fab disabled aria-label="Example icon button with a home icon">
          <mat-icon> call</mat-icon>
        </button>
      </div>
      <div><a href="whatsapp://send?abid=9319968597&text=Hello%2C%20FIINZO!">+91-9319968597 (Whatsapp only)</a></div>
    </mat-list-item>
      <mat-list-item> <div class="example-button-container">
        <button mat-mini-fab disabled aria-label="Example icon button with a home icon">
          <mat-icon> mail_outline</mat-icon>
         
        </button>
      </div>
    <div><a href="maito:info@fiinzo.com"> info@fiinzo.com</a></div></mat-list-item>
      <mat-list-item> <div class="example-button-container">
        <button mat-mini-fab disabled aria-label="Example icon button with a home icon">
          <mat-icon>where_to_vote</mat-icon>
        </button>
      </div>
    <div>106 Guru Ram Das Bhawan <br>
      Near Satyam Cineplex Patel Nagar,<br>
      New Delhi -110008 </div></mat-list-item>
    </mat-list>
    <div class="links">
      <a href="/terms" target="_blank">Terms & Conditions</a>
      <a href="/policy" target="_blank">Privacy Policy</a>
      <a href="/refundPolicy" target="_blank">Refund Policy</a>
    </div>
</div>