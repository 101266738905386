import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "src/app/services/core/authentication.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {}

  /**
   * Method to remove token from local storage and redirect user to login
   */
  logout() {
    this.authService.logout();
    this.router.navigate(["/login"], {
      queryParams: { returnUrl: this.router.routerState.snapshot.url },
    });
  }

  openSettings() {
    this.router.navigateByUrl("/admin/settings");
  }
  openPayout() {
    this.router.navigateByUrl("/admin/payment/payout");
  }
  openTransactions() {
    this.router.navigateByUrl("/admin/payment/transaction");
  }
  openHelpSection(){
    this.router.navigateByUrl("/admin/help");
  }
}
