import { Validators } from '@angular/forms';
import { Injectable } from "@angular/core";

@Injectable()
export class LoginValidationConfig {

  getLoginForm() {
    return {
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    }
  }

  getForgotForm() {
    return {
      email: ['', [Validators.required, Validators.email]]      
    }
  }
  getVerificationForm() {
    return {
      verification_code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]      
    }
  }
  
  getResetForm() {
    return {
      email: ['', [Validators.required, Validators.email]],    
      password: ['', Validators.required]   
    }
  }
}

