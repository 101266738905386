<app-fiinzo-header></app-fiinzo-header>
<div class="cms-page-container">
    <h2>Terms and Conditions</h2>
    The terms "We" / "Us" / "Our"/” "Company" individually and collectively refer to Hythe Solutions Private Limited and
    the terms "Visitor” ”User” refer to the users.

    This page states the Terms and Conditions under which you (Visitor) may use the FIINZO Website/App. Please read this
    page carefully. If you do not accept the Terms and Conditions stated here, we would request you to exit this
    Website/App. The business, any of its business divisions and / or its subsidiaries, associate companies or
    subsidiaries to subsidiaries or such other investment companies (in India or abroad) reserve their respective rights
    to revise these Terms and Conditions at any time by updating this posting. You should visit this page periodically
    to re-appraise yourself of the Terms and Conditions, because they are binding on all users of this Website/App.

    <h4>USE OF CONTENT</h4>
    All logos, brands, marks headings, labels, names, signatures, numerals, shapes or any combinations thereof,
    appearing in this site, except as otherwise noted, are properties either owned, or used under licence, by the
    business and / or its associate entities who feature on this Website/App.The use of these properties or any other
    content on this Website/App, except as provided in these terms and conditions or in the Website/App content, is
    strictly prohibited.

    You may not sell or modify the content of this Website/App or reproduce, display, publicly perform, distribute, or
    otherwise use the materials in any way for any public or commercial purpose without the respective organisation’s or
    entity’s written permission.

    <h4>ACCEPTABLE WEBSITE/App USE</h4>

    (A) Security Rules
    Visitors are prohibited from violating or attempting to violate the security of the Website/App, including, without
    limitation, (1) accessing data not intended for such user or logging into a server or account which the user is not
    authorised to access, (2) attempting to probe, scan or test the vulnerability of a system or network or to breach
    security or authentication measures without proper authorisation, (3) attempting to interfere with service to any
    user, host or network, including, without limitation, via means of submitting a virus or "Trojan horse" to the
    Website/App, overloading, "flooding", "mail bombing" or "crashing", or (4) sending unsolicited electronic mail,
    including promotions and/or advertising of products or services. Violations of system or network security may result
    in civil or criminal liability. The business and / or its associate entities will have the right to investigate
    occurrences that they suspect as involving such violations and will have the right to involve, and cooperate with,
    law enforcement authorities in prosecuting users who are involved in such violations.

    (B) General Rules
    Visitors may not use the Website/App in order to transmit, distribute, store or destroy material (a) that could
    constitute or encourage conduct that would be considered a criminal offence or violate any applicable law or
    regulation, (b) in a manner that will infringe the copyright, trademark, trade secret or other intellectual property
    rights of others or violate the privacy or publicity of other personal rights of others, or (c) that is libellous,
    defamatory, pornographic, profane, obscene, threatening, abusive or hateful.

    <h4>INDEMNITY</h4>

    The User unilaterally agree to indemnify and hold harmless, without objection, the Company, its officers, directors,
    employees and agents from and against any claims, actions and/or demands and/or liabilities and/or losses and/or
    damages whatsoever arising from or resulting from their use of FIINZO Website/App or their breach of the terms .

    <h4>LIABILITY</h4>

    User agrees that neither Company nor its group companies, directors, officers or employee shall be liable for any
    direct or/and indirect or/and incidental or/and special or/and consequential or/and exemplary damages, resulting
    from the use or/and the inability to use the service or/and for cost of procurement of substitute goods or/and
    services or resulting from any goods or/and data or/and information or/and services purchased or/and obtained or/and
    messages received or/and transactions entered into through or/and from the service or/and resulting from
    unauthorized access to or/and alteration of user's transmissions or/and data or/and arising from any other matter
    relating to the service, including but not limited to, damages for loss of profits or/and use or/and data or other
    intangible, even if Company has been advised of the possibility of such damages.
    User further agrees that Company shall not be liable for any damages arising from interruption, suspension or
    termination of service, including but not limited to direct or/and indirect or/and incidental or/and special
    consequential or/and exemplary damages, whether such interruption or/and suspension or/and termination was justified
    or not, negligent or intentional, inadvertent or advertent.
    User agrees that Company shall not be responsible or liable to user, or anyone, for the statements or conduct of any
    third party of the service. In sum, in no event shall Company's total liability to the User for all damages or/and
    losses or/and causes of action exceed the amount paid by the User to Company, if any, that is related to the cause
    of action.

    <h4>DISCLAIMER OF CONSEQUENTIAL DAMAGES</h4>

    In no event shall Company or any parties, organizations or entities associated with the corporate brand name us or
    otherwise, mentioned at this Website/App be liable for any damages whatsoever (including, without limitations,
    incidental and consequential damages, lost profits, or damage to computer hardware or loss of data information or
    business interruption) resulting from the use or inability to use the Website and the Website material, whether
    based on warranty, contract, tort, or any other legal theory, and whether or not, such organization or entities were
    advised of the possibility of such damages.

    <h4>Refund Policy</h4>

    Our focus is complete customer satisfaction. In the event, if you are displeased with the services provided, we will
    refund back the money, provided the reasons are genuine and proved after investigation. Please read the fine prints
    of each deal before buying it, it provides all the details about the services or the product you purchase.

    In case of dissatisfaction from our services, clients have the liberty to cancel their orders and request a refund
    from us. Our Policy for the cancellation and refund will be as follows:
    <!-- Refund Policy
        
        We will try our best to create the suitable design concepts for our customers.
        In case any customer is not completely satisfied with our products we can provide a refund.
        If paid by credit card, refunds will be issued to the original credit card provided at the time of purchase and in case of payment gateway name payments refund will be made to the same account. -->
</div>


<app-fiinzo-footer></app-fiinzo-footer>