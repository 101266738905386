<app-fiinzo-header></app-fiinzo-header>
<div class="cms-page-container">
    <h2>Refund Policy</h2>
    Our focus is complete customer satisfaction. In the event, if you are displeased with the services provided, we will
    refund back the FIINZO points in your FIINZO account, provided the reasons are genuine and proved after investigation.
    In case of dissatisfaction from our services, clients have the liberty to request a refund in the form of FIINZO points as mentioned 
    above from us. Our Policy for refund will be as follows:
    <br>

    We will try our best to create the suitable design concepts for our customers.
    <br>
    In case any customer is not completely satisfied with our products we can provide a refund.
    <br>
    Refund Requests received later than 48hrs will not be considered.

</div>


<app-fiinzo-footer></app-fiinzo-footer>