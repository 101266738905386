<div class="app-navbar">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <button
        mat-icon-button
        (click)="sidenav.toggle()"
        fxShow="true"
        fxHide.gt-sm
      >
        <mat-icon>menu</mat-icon>
      </button>
      <span
        ><a routerLink="dashboard" mat-button class="header-bar-link"
          ><img src="../../assets/img/logo-512x512.png" alt="" class="logo" width="80px" height="80px"></a
        ></span
      >
      <span class="menu-spacer"></span>

      <div fxShow="true" fxHide.lt-md class="main-menu">
        <!-- The following menu items will be hidden on both SM and XS screen sizes -->
        <a routerLink="dashboard" routerLinkActive="active" mat-button>
          <mat-icon
            aria-hidden="false"
            aria-label="Membership Plans"
            class="main-menu-mat-icon"
            >card_membership
          </mat-icon>
          Dashboard
        </a>
        <a routerLink="user" routerLinkActive="active" mat-button>
          <mat-icon
            aria-hidden="false"
            aria-label="Dashboard"
            class="main-menu-mat-icon"
            >people</mat-icon
          >
          Users
        </a>
        <a routerLink="advisors" routerLinkActive="active" mat-button>
          <mat-icon
            aria-hidden="false"
            aria-label="Customer"
            class="main-menu-mat-icon"
            >supervised_user_circle
          </mat-icon>
          Financial Advisors
        </a>
        <!-- <a routerLink="transaction" routerLinkActive="active" mat-button>
          <mat-icon aria-hidden="false" aria-label="Transaction" class="main-menu-mat-icon">monetization_on</mat-icon>
          Transaction
        </a> -->
        <a
          mat-button
          [matMenuTriggerFor]="transactionMenu"
          routerLinkActive="active"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="Transaction"
            class="main-menu-mat-icon"
            >monetization_on</mat-icon
          >
          Payment
        </a>
        <mat-menu #transactionMenu="matMenu" class="sub-menu">
          <button mat-menu-item (click)="openTransactions()">
            Transaction
          </button>
          <button mat-menu-item (click)="openPayout()">Payout</button>
        </mat-menu>

        <a routerLink="content" routerLinkActive="active" mat-button>
          <mat-icon
            aria-hidden="false"
            aria-label="Transaction"
            class="main-menu-mat-icon"
            >pages</mat-icon
          >
          CMS
        </a>
        <a
          mat-button
          [matMenuTriggerFor]="settingMenu"
          routerLinkActive="active"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="Setting"
            class="main-menu-mat-icon"
            >settings</mat-icon
          >
          Setting
        </a>
        <mat-menu #settingMenu="matMenu" class="sub-menu">
          <button mat-menu-item (click)="openSettings()">Setting</button>
          <button mat-menu-item (click)="openHelpSection()">Help</button>
          <button mat-menu-item (click)="logout()">Logout</button>
        </mat-menu>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container fxFlexFill>
    <mat-sidenav #sidenav>
      <mat-nav-list>
        <a routerLink="dashboard" routerLinkActive="active" mat-button
          >Dashboard</a
        >
        <a routerLink="customer" mat-button>Customer</a>
        <a routerLink="vendor" mat-button>Vendor</a>
        <a routerLink="#" mat-button>Category</a>
        <a routerLink="#" mat-button>Membership Plans</a>
        <a routerLink="#" mat-button>Transaction</a>
        <a routerLink="#" mat-button>Setting</a>
      </mat-nav-list>
    </mat-sidenav>
    <!--<mat-sidenav-content fxFlexFill>Main content</mat-sidenav-content>-->
  </mat-sidenav-container>
</div>
