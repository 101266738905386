import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "src/app/services/core/authentication.service";
import { FormGroup, FormBuilder } from "@angular/forms";
import { LoginValidationConfig } from "src/app/validators/login-validation-config";
import { first } from "rxjs/operators";
import { ToasterService } from "src/app/services/toaster.service";
import {
  DefaultScreen,
  Error,
  Message,
} from "src/app/constants/constant.const";

@Component({
  selector: "app-forgot",
  templateUrl: "./forgot.component.html",
  styleUrls: ["./forgot.component.scss"],
})
export class ForgotComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  loginError = false;
  errorMsg: string;
  defaultScreen = DefaultScreen.FORGOT;
  headerSection = "Forgot Password";
  url: string;
  navigateUrl: string;

  constructor(
    private router: Router,
    private loginService: AuthenticationService,
    private fb: FormBuilder,
    private vc: LoginValidationConfig,
    private activatedRoute: ActivatedRoute,
    private readonly toasterService: ToasterService
  ) {}

  /**
   * initializes the component
   */
  ngOnInit() {
    // get return url from route parameters or default to '/'
    //this.returnUrl = this.router.snapshot.queryParams["returnUrl"] || "/admin";
    this.loginForm = this.fb.group(this.vc.getForgotForm());
    this.activatedRoute.params.subscribe((data) => {
      if (data && data.id === DefaultScreen.VERIFY) {
        this.loginForm = this.fb.group(this.vc.getVerificationForm());
        this.defaultScreen = DefaultScreen.VERIFY;
        this.headerSection = "Verification";
      }
      if (data && data.id === DefaultScreen.RESET) {
        let data = sessionStorage.getItem("requestedEmail");
        this.loginForm = this.fb.group(this.vc.getResetForm());
        this.defaultScreen = DefaultScreen.RESET;
        this.headerSection = "Reset Password";
        if (data) {
          this.loginForm.patchValue({
            email: data,
          });
        }
      }
    });
  }

  /**
   * processes the login credentials
   * @memberof LoginComponent
   */
  login() {
    switch (this.defaultScreen) {
      case DefaultScreen.FORGOT:
        this.url = "sendcode";
        break;
      case DefaultScreen.VERIFY:
        this.url = "verifycode";
        break;
      case DefaultScreen.RESET:
        this.url = "updatepassword";
        break;
      default:
        this.url = "sendcode";
        break;
    }
    this.loginService
      .auth(this.loginForm.value, this.url)
      .pipe(first())
      .subscribe(this.loginObserver);
  }

  private loginObserver = {
    next: (data) => {
      if (this.defaultScreen == DefaultScreen.FORGOT) {
        this.toasterService.openSnackBar(data.message);
        this.router.navigate(["forgot", "verify"]);
      } else if (this.defaultScreen == DefaultScreen.VERIFY) {
        this.toasterService.openSnackBar(Message.ENTER_PASSWORD);
        sessionStorage.setItem("requestedEmail", data.email);
        this.router.navigate(["forgot", "reset"]);
      } else {
        this.toasterService.openSnackBar(Message.UPDATE_SUCCESS);
        sessionStorage.removeItem("requestedEmail");
        this.router.navigate(["login"]);
      }
    },
    error: (err) => {
      this.loginError = true;
      if (this.defaultScreen === DefaultScreen.FORGOT) {
        this.toasterService.openSnackBar(
          Error.EMAIL_NOT_REGISTERED,
          null,
          true
        );
      } else if (this.defaultScreen === DefaultScreen.VERIFY) {
        this.toasterService.openSnackBar(Error.VERIFY_ERROR, null, true);
      } else {
        this.toasterService.openSnackBar(Error.GENERIC_ERROR, null, true);
      }
    },
    complete: () => {},
  };

  ngOnDestroy() {
    sessionStorage.removeItem("requestedEmail");
  }
}
