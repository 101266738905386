<app-fiinzo-header></app-fiinzo-header>

<div class="bannerarea" style="background-image: url(../../assets/img/bannerbg.png);">

  <mat-list>
    <mat-list-item>
      <div>
        <img src="../../assets/img/logo-512x512.png" alt="FIINZO" class="logo" width="120px" height="120px">
        <h1>FIINZO is your one stop solution <br /> for all your financial queries</h1>

        <h4 class="sub-heading">Fiinzo is a platform where you can directly connect with verified experts.
          <br> Connect with a policy expert, Investment expert, Loan Expert, Tax Expert, Company Compliances expert <br>
          and get your doubts cleared in easy and hassle free manner from the comfort of your home.
        </h4>
        <div class="batchs">
          <div class="downloadapp">
            <div class="text-left">
              <!-- <img src="../../assets/img/ios.png" class="button" alt="" width="140px" >  -->
              <a href="https://play.google.com/store/apps/details?id=com.app.fiinzo" target="_blank">
                <img src="../../assets/img/android.png" alt="" class="button" width="140px">
              </a>
            </div>
          </div>
        </div>
      </div>



    </mat-list-item>
  </mat-list>
</div>

<div class="appsection" style="background-image: url(../../assets/img/bg-section.png);"> </div>

<div class="Servicessection" style="text-align:center;">

  <div style="max-width:1200px;margin:0 auto;">
    <mat-list>
      <mat-list-item>
        <div class="box" style="text-align: right;">
          <h3>How FIINZO Can Help </h3>
          <label> Connect with experts 24/7.Connect with an expert even on holidays
          </label>

          <h3>FIINZO Cares For You</h3>
          <label>Connect from the safety of your home.Save Time and money.No Long queues </label>
          <h3>Earn Money in A Hassle Free Manner </h3>
          <label>Experts can register easily on FIINZO and can easily earn money from the comfort of their home </label>
        </div>
        <div class="box">
          <h2>Our Services</h2>
          <h3> <img src="../../assets/img/homescreen.png" alt="" class="button" width="280px"></h3>
        </div>
        <div class="box" style="text-align: left;">
          <h3>How FIINZO Works </h3>
          <label>Ask an expert get a professional answer</label>
          <h3>Connect with wide variety of experts </h3>
          <label> On FIINZO you can connect with policy expert,loan expert,investment expert,tax Expert,company
            compliances expert and get your queries resolved</label>

          <h3>Easy On Your Pocket</h3>
          <label> FIINZO provides cost effective way to get your queries answered by verified experts</label>
        </div>
      </mat-list-item>


    </mat-list>
  </div>
</div>
<app-fiinzo-footer></app-fiinzo-footer>