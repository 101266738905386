import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Idle } from '@ng-idle/core';
import { Subscription } from 'rxjs';
import { TimeoutModal } from 'src/app/constants/timeout-modal.const';

@Component({
  selector: 'app-timeout-modal',
  templateUrl: './timeout-modal.component.html',
  styleUrls: ['./timeout-modal.component.css']
})
export class TimeoutModalComponent implements OnInit, OnDestroy {

  onTimeoutWarning$: Subscription;
  idleState: number = TimeoutModal.TIMEOUT_ALERT_TIME;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private idle: Idle
  ) { }

  ngOnInit(): void {
    this.onTimeoutWarning$ = this.idle.onTimeoutWarning.subscribe((countdown: number) => {
      this.idleState = countdown;
    }
    );
  }

  ngOnDestroy() {
    this.onTimeoutWarning$.unsubscribe();
  }
}
