import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, first } from 'rxjs/operators';
import { TimeoutModal } from 'src/app/constants/timeout-modal.const';
import { AuthenticationService } from 'src/app/services/core/authentication.service';
import { TimeoutModalComponent } from '../../shared/timeout-modal/timeout-modal.component';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.Component.html'
})
export class AdminComponent implements OnInit, OnDestroy {

    timedOut = false;
    timeoutModal: MatDialogRef<any, any>;
    onTimeout$: Subscription;
    onTimeoutWarning$: Subscription;

    constructor(
        private readonly idle: Idle,
        private readonly router: Router,
        private readonly authService: AuthenticationService,
        private readonly dialog: MatDialog,
        private readonly activatedRoute: ActivatedRoute
    ) {
        this.router.events.pipe(first(),distinctUntilChanged()).subscribe(() => {
            console.log({ "this.activatedRoute": this.activatedRoute.snapshot.url });
        })
    }

    ngOnInit() {

        this.idle.setIdle(TimeoutModal.IDLE_TIME);

        this.idle.setTimeout(TimeoutModal.TIMEOUT_ALERT_TIME);

        /** sets the default interrupts, in this case, things like clicks, scrolls, touches to the document */
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.onTimeout$ = this.idle.onTimeout.subscribe(() => {
            this.timeoutModal.close();
            this.timedOut = true;
            this.authService.logout();
            this.router.navigate(['/login']);
        });

        this.onTimeoutWarning$ = this.idle.onTimeoutWarning.subscribe((countdown: number) => {
            if (countdown === TimeoutModal.TIMEOUT_ALERT_TIME) {
                if (this.timeoutModal) this.timeoutModal.close();
                this.timeoutModal = this.dialog.open(TimeoutModalComponent, {
                    data: countdown
                });
            }
        }
        );

        this.reset();
    }

    reset() {
        this.idle.watch();
        this.timedOut = false;
    }

    ngOnDestroy() {
        this.onTimeout$.unsubscribe();
        this.onTimeoutWarning$.unsubscribe();
    }

}