import { Component, OnInit, VERSION, ViewChild } from '@angular/core';

@Component({
  selector: 'app-fiinzo-footer',
  templateUrl: './fiinzo-footer.component.html',
  styleUrls: ['./fiinzo-footer.component.scss']
})
export class FiinzoFooterComponent implements OnInit {
constructor() { }

  ngOnInit() {}

}
