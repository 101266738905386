<app-loader></app-loader>
<section>
  <mat-card class="login-card">
    <mat-card-header>
      <mat-card-title>
        <h3 class="login-text">{{headerSection}}</h3>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form class="login-form" [formGroup]="loginForm" (submit)="login()">
        <table class="login-full-width" cellspacing="0">
          <tr>
            <td>
              <mat-form-field class="login-full-width" *ngIf="defaultScreen === 'forgot'">
                <input matInput placeholder="Email" type="text" formControlName="email" autocomplete="disabled" />
              </mat-form-field>
              <mat-form-field class="login-full-width" *ngIf="defaultScreen === 'verify'">
                <input matInput placeholder="Verification code" type="text" formControlName="verification_code" autocomplete="disabled" />
              </mat-form-field>
              <mat-form-field class="login-full-width" *ngIf="defaultScreen === 'reset'">
                <!-- <input matInput placeholder="Email" type="text" formControlName="email" autocomplete="disabled" /> -->
                <input matInput placeholder="Password" type="password" formControlName="password" autocomplete="disabled" />
              </mat-form-field>
            </td>
          </tr>          
        </table>
        
      </form>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button (click)="login()" color="primary" [disabled]="!loginForm.valid">Submit</button>
      <a mat-stroked-button routerLink="/login">login</a>
    </mat-card-actions>
  </mat-card>
</section>
<app-footer></app-footer>