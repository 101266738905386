import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/core/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  loading: boolean;

  constructor(private loaderService: LoaderService) { }

  ngOnInit(): void {

    this.loaderService.isLoading.subscribe((res) => {
      this.loading = res;
    });

  }

}
