<app-fiinzo-header></app-fiinzo-header>
<div class="cms-page-container">
    <h2>Privacy Policy</h2>
    
    The terms "We" / "Us" / "Our" /” "Company” individually and collectively refer to Hythe Solutions Private Limited
    and the terms "You" /"Your" / "Yourself" refer to the users.

    This Privacy Policy is an electronic record in the form of an electronic contract formed under the information
    Technology Act, 2000 and the rules made thereunder and the amended provisions pertaining to electronic documents /
    records in various statutes(review) as amended by the information Technology Act, 2000. This Privacy Policy does not
    require any physical, electronic or digital signature.

    This Privacy Policy is a legally binding document between you and Hythe Solutions Private Limited (both terms
    defined above). The terms of this Privacy Policy will be effective upon your acceptance of the same (directly or
    indirectly in electronic form, by clicking on the I accept tab or by use of the website/App or by other means) and
    will govern the relationship between you and Hythe Solutions Private Limited for your use of the FIINZO Website/App.

    This document is published and shall be constructed in accordance with the provisions of the Information Technology
    (reasonable security practices and procedures and sensitive personal data of information) rules, 2011 under
    Information Technology Act, 2000; that require publishing of the Privacy Policy for collection, use, storage and
    transfer of sensitive personal data or information.

    Please read this Privacy Policy carefully.By using the Website/App you indicate that you understand, agree and
    consent to this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use this
    Website/App.

    By providing us your Information or by making use of the facilities provided by the Website/App, You hereby consent
    to the collection, storage, processing and transfer of any or all of Your Personal Information and Non-Personal
    Information by us as specified under this Privacy Policy. You further agree that such collection, use, storage and
    transfer of Your Information shall not cause any loss or wrongful gain to you or any other person.

    <h4>USER INFORMATION</h4>
    
    To avail certain services on our Website/App, users are required to provide certain information for the registration
    process namely: - a) mobile number, b) otp received in mobile. If you will be added as an Expert then the Expert profile photo is mandatory for verification
    purpose which can be uploaded via accessing your mobile camera through our app. The Information as supplied by the
    users enables us to improve our sites and provide you the most user-friendly experience.

    All required information is service dependent and we may use the above said user information to, maintain, protect,
    and improve its services (including advertising services) and for developing new services

    Such information will not be considered as sensitive if it is freely available and accessible in the public domain
    or is furnished under the Right to Information Act, 2005 or any other law for the time being in force.

    <h4>INFORMATION SHARING</h4>
    We shares the sensitive personal information to any third party without obtaining the prior consent of the user in
    the following limited circumstances:

    (a) When it is requested or required by law or by any court or governmental agency or authority to disclose, for the
    purpose of verification of identity, or for the prevention, detection, investigation including cyber incidents, or
    for prosecution and punishment of offences. These disclosures are made in good faith and belief that such disclosure
    is reasonably necessary for enforcing these Terms; for complying with the applicable laws and regulations.

    (b) We proposes to share such information within its group companies and officers and employees of such group
    companies for the purpose of processing personal information on its behalf. We also ensure that these recipients of
    such information agree to process such information based on our instructions and in compliance with this Privacy
    Policy and any other appropriate confidentiality and security measures.

    <h4>INFORMATION SECURITY</h4>
    We take appropriate security measures to protect against unauthorized access to or unauthorized alteration,
    disclosure or destruction of data. These include internal reviews of our data collection, storage and processing
    practices and security measures, including appropriate encryption and physical security measures to guard against
    unauthorized access to systems where we store personal data.

    All information gathered on our Website/App is securely stored within our controlled database. The database is
    stored on servers secured behind a firewall; access to the servers is password-protected and is strictly limited.
    However, as effective as our security measures are, no security system is impenetrable. We cannot guarantee the
    security of our database, nor can we guarantee that information you supply will not be intercepted while being
    transmitted to us over the Internet. And, of course, any information you include in a posting to the discussion
    areas is available to anyone with Internet access.

    However the internet is an ever evolving medium. We may change our Privacy Policy from time to time to incorporate
    necessary future changes. Of course, our use of any information we gather will always be consistent with the policy
    under which the information was collected, regardless of what the new policy may be.

    <h4>Grievance Redressal</h4>
    Redressal Mechanism: Any complaints, abuse or concerns with regards to content and or comment or breach of these
    terms shall be immediately informed to the designated Grievance Officer as mentioned below via in writing or through
    email signed with the electronic signature to Hythe Solutions Private Limited. ("Grievance Officer").

    Hythe Solutions Private Limited (Grievance Officer)
    www.hythesolutions.com
    106 Guru Ram Das Bhawan
    Near Satyam Cineplex Patel Nagar
    New Delhi -110008
    Email: info@hythesolutions.com
</div>
<app-fiinzo-footer></app-fiinzo-footer>