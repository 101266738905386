import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./routes/app-routing.module";
import { AppComponent } from "./app.component";

import { CustomMaterialModule } from "./components/material/material.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoginComponent } from "./components/core/login/login.component";
import { AdminComponent } from "./components/core/admin/admin.component";
import { HeaderComponent } from "./components/core/header/header.component";
import { FooterComponent } from "./components/core/footer/footer.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoginValidationConfig } from "./validators/login-validation-config";
import { JwtInterceptor } from "./interceptor/jwt.interceptor";
import { LoaderComponent } from "./components/core/loader/loader.component";
import { LoaderInterceptor } from "./interceptor/loader.interceptor";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { ErrorInterceptor } from "./interceptor/error.interceptor";
import { ForgotComponent } from "./components/core/forgot/forgot.component";
import { HomeComponent } from './components/core/home/home.component';
import { FiinzoFooterComponent } from "./components/core/fiinzo-footer/fiinzo-footer.component";
import { FiinzoHeaderComponent } from "./components/core/fiinzo-header/fiinzo-header.component";
import { TermsConditionsComponent } from "./components/core/terms-conditions/terms-conditions.component";
import { RefundPolicyComponent } from "./components/core/refund-policy/refund-policy.component";
import { PrivacyPolicyComponent } from "./components/core/privacy-policy/privacy-policy.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminComponent,
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    ForgotComponent,
    HomeComponent,
    FiinzoFooterComponent,
    FiinzoHeaderComponent,
    TermsConditionsComponent,
    RefundPolicyComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    CustomMaterialModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    LoginValidationConfig,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports:[FiinzoFooterComponent,FiinzoHeaderComponent]
})
export class AppModule {}
