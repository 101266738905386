<div class="fiinzo-header" style="top: 0px;position: fixed;width: 100%;">
  <mat-toolbar color="primary">
    <span>
      <a href="/" mat-button class="header-bar-link"
      ><img src="../../assets/img/logo-512x512.png" alt="" class="logo" width="80px" height="80px">
      </a
    ></span>
  <span style="flex:1"></span>
  <div class="links">
    <a href="/terms" >Terms & Conditions</a>
    <a href="/policy" >Privacy Policy</a>
    <a href="/refundPolicy" >Refund Policy</a>
  </div>
</mat-toolbar>
</div>